@media print {
    body * {
        visibility: hidden;
    }
    /* show the list and adjust its layout for printing */
    .printable-list {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
    }
    .printable-list *{
        visibility: visible;

    }
  }